import React from 'react'
import { graphql } from 'gatsby'
import { css } from '@emotion/core'
import Layout from 'components/Layout'
import Container from 'components/Container'
import Helmet from 'react-helmet'


export default function NewsLetterPage({ data: { site, allMdx } }) {
  return (
    <Layout site={site} noFooter={false}>
      <Helmet>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@chrisachard" />
        <meta name="twitter:title" content='Chris Achard' />
        <meta name="twitter:description" content='Teaching everything I know about software: React, JS, Rails, Node.js' />
        <meta name="twitter:image" content='https://chrisachard.com/chris_twitter.png' />
      </Helmet>

      <Container
        css={css`
          padding-bottom: 0;
        `}
      >

        <h1><span role="img" aria-label="email newsletter" aria-labelledby="">📬</span> Newsletter</h1>

        <p>
          The newsletter comes out about once a week, and it contains all of my best thoughts about how to be a better software developer.
        </p>
        <p>
          This is where I really try to distill down what it takes to succeed with code - and I share my hard earned lessons about what that takes, and what it means.
        </p>
        <p>
          I want you to succeed! <span role="img" aria-label="rocket" aria-labelledby="">🚀</span>
        </p>
        <p>
          I don't post the newsletter content anywhere else, and there's no fluff - only actionable advice on how to be an awesome software developer.
        </p>
        <p>So sign up below to be awesome!</p>

      </Container>
    </Layout>
  )
}


export const pageQuery = graphql`
  query {
    site {
      ...site
      siteMetadata {
        title
      }
    }
  }
`
